<template>
    <el-dialog :visible="visible" width="800px" class="dialog" @close="handleClose">
        <div style="max-height: 400px;overflow: auto">
            <div ref="print" style="padding: 20px;letter-spacing: 1px;font-family: 'SimSun', '宋体', Arial, sans-serif;"
                 id="printTest">
                <el-row style="page-break-after:always">
                    <div class="imags-logo">
                        <img src="../../../assets/logo.jpg">
                    </div>
                    <div class="text-title">团检报告</div>
                    <div class="imags">
                        <img src="../../../assets/report.jpg">
                    </div>
                    <el-row class="tjp">
                        <div>参检单位名称: 公司女工体检</div>
                        <div style="padding-left: 20px">体检日期： 二○二三年度</div>
                        <div>体检预约电话：5804094</div>
                        <div>报告咨询电话：5805005</div>
                    </el-row>
                </el-row>
                <el-row class="twopage" style="page-break-after:always">
                    <div>
                        <div class="title">
                            前 言
                        </div>
                        <div class="tw-title-text">
                            这是一份针对贵单位女职工的体检结果综合分析报告，希望透过本报告，能够了解女职工的生殖系统身体健康状况及改善方向。
                        </div>
                        <div class="tw-title-text">
                            这份报告包括二部分：第一部分；参检女工的年龄分布情况及体检异常情况统计；第二部分；根据贵公司的体检结果我们给出了女工的常见疾病健康建议。我们将统计女性生殖健康检查结果，并针对常见疾病提供相应的健康指导建议，以便指导今后如何改善健康采取相应的促进活动。
                        </div>
                        <div class="tw-title-text">
                            大庆油田总医院体检中心引进全国先进的全自动化体检系统（体检软件和健康管理软件），实现所有体检数据自动传输和终身保存，实现检后亚健康人群的疾病风险评估、健康促进及膳食、运动、心理等全方位健康干预。并针对团体体检结果做出“统计分析”后，中心将提供健康教育与改善方案、健康计划实施追踪等；并针对健康问题多及疾病风险高的疾病，组织我院知名临床医生进行专场咨询讲解。
                        </div>
                        <div class="qr">
                            <div class="title-text">
                                大庆油田总医院公众号：dqytzyy
                            </div>
                            <div>
                                <img src="../../../assets/qr.jpg">
                            </div>
                        </div>
                    </div>
                </el-row>
                <el-row class="three-page twopage" style="page-break-after:always">
                    <div>
                        <div class="title-text">一、综合体检分析表</div>
                        <div>（一）年龄及性别分布</div>
                        <div class="tw-title-text">
                            贵公司此次参检女工共  ***人，已完成体检***人，体检率*** %。参检人员平均年龄为***岁，21-30岁、31-40岁、41-50岁、51-55岁各年龄段占总体检人数的***%、***%、***%、***%；本次体检以***岁人员为主。
                        </div>
                        <div style="padding-top: 60px">
                            <table border="0" cellspacing="0" cellpadding="0" width="100%"
                                   style="margin: auto!important;">
                                <tbody>
                                <tr>
                                    <th>年龄段</th>
                                    <th colspan="2">21-30</th>
                                    <th colspan="2">31-40</th>
                                    <th colspan="2">41-50</th>
                                    <th colspan="2">51-60</th>
                                    <th width="300px" rowspan="2">合计人数</th>
                                </tr>
                                <tr>
                                    <td width="280px" rowspan="2">女工</td>
                                    <td width="200px">人数</td>
                                    <td width="200px">百分比</td>
                                    <td width="200px">人数</td>
                                    <td width="200px">百分比</td>
                                    <td width="200px">人数</td>
                                    <td width="200px">百分比</td>
                                    <td width="200px">人数</td>
                                    <td width="200px">百分比</td>
                                </tr>
                                <tr >
                                    <td>1</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <nvBar :bardata="[]"></nvBar>
                        </div>
                        <div>
                            <div>（二）、职工健康体检具体检出具体异常例数及占比</div>
                            <div class="tw-title-text">
                            此次体检贵单位参检女工共 *** 人，已完成体检 *** 人，发现异常 *** 人，异常率 *** %。
                            具体异常例数及比例按年龄段分布如下;
                            </div>
                            <div>
                                <div style="padding-top: 60px">
                                    <table border="0" cellspacing="0" cellpadding="0" width="100%"
                                           style="margin: auto!important;">
                                        <tbody>
                                        <tr>
                                            <th>序号</th>
                                            <th>年龄段</th>
                                            <th colspan="2">21-30</th>
                                            <th colspan="2">31-40</th>
                                            <th colspan="2">41-50</th>
                                            <th colspan="2">51-60</th>
                                            <th width="300px" rowspan="2">合计</th>
                                            <th width="300px" rowspan="2">占总人数比例</th>
                                        </tr>
                                        <tr>
                                            <td width="100px"></td>
                                            <td width="280px">异常</td>
                                            <td width="200px">人数</td>
                                            <td width="200px">占该年龄段比例</td>
                                            <td width="200px">人数</td>
                                            <td width="200px">占该年龄段比例</td>
                                            <td width="200px">人数</td>
                                            <td width="200px">占该年龄段比例</td>
                                            <td width="200px">人数</td>
                                            <td width="200px">占该年龄段比例</td>
                                        </tr>
                                        <tr v-for="(item,index) in form.tableData" :key="index">
                                            <td>{{index+1}}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <yichang-bar :bardata="[]"></yichang-bar>
                            </div>
                        </div>
                        <div>
                            <div class="title-text">二、女性常见疾病解释和健康建议</div>
                            <div class="title-text">1  乳腺增生</div>
                            <div class="tw-title-text">
                                由于卵巢功能失调,乳腺组织增生或复原不全而致导管囊状扩张,小叶及其周围纤维组织增生。一般表现为随月经变化乳房呈周期性胀痛，伴或不伴乳内结节或肿物。经前期疼痛渐重，经后症状渐减或消失，一般认为与女性激素有关。是本病特征。请到外科咨询，并定期复查B超。若发现乳房内有肿块，乳头有溢液，请及时诊治
                            </div>
                            <div class="title-text">2  宫颈纳囊</div>
                            <div class="tw-title-text">
                                【疾病解释】宫颈腺囊肿绝大多数情况下是子宫颈的生理性变化。子宫颈转化区内鳞状上皮取代柱状上皮过程中，新生的鳞状上皮覆盖宫颈腺管口或伸入腺管，将腺管口阻塞，导致腺体分泌物引流受阻，潴留形成囊肿。子宫颈局部损伤或宫颈慢性炎症使腺管口狭窄，也可导致宫颈腺囊肿形成。宫颈腺囊肿通常不需处理，但深部的宫颈腺囊肿，子宫颈表面无异常，表现为宫颈肥大，应与宫颈腺癌鉴别。
                            </div>
                            <div class="tw-title-text">
                                【治疗建议】无需特殊处理，妇科门诊随诊。
                            </div>
                            <div class="title-text">3   乳腺结节</div>
                            <div class="tw-title-text">
                                【疾病解释】乳腺结节是一种症状，常见于乳腺增生（可形成乳腺囊肿）及乳腺肿瘤性疾病，包括乳腺良性肿瘤（如乳腺纤维瘤、分叶状肿瘤等）以及乳腺恶性肿瘤（乳腺癌）。乳腺结节的成因尚不明确，与多种遗传及环境因素相关。目前可能参与乳腺结节形成的病因包括内分泌激素水平、基因突变及环境影响等。
                            </div>
                            <div class="tw-title-text">
                                【健康建议】大部分乳腺结节是良性的，判断乳腺结节良恶性一般根据B超和乳腺钼靶片的表现分级（BI-RADS系统通常分为6个级别），前3个级别的乳腺结节一般属于良性结节，患者无需特殊治疗，只需要按医生要求定期复查即可。乳腺结节分级在BI-RADS 4级以上就有一定的恶性几率了，在这个时候我们要进一步的检查。
                            </div>
                            <div class="tw-title-text">
                                【生活指导】鉴于乳腺结节的病因尚不清楚，目前无明确有效预防手段，但可以针对乳腺癌的高危因素进行相关防控措施：⑴健康生活方式，不抽烟、不酗酒；⑵饮食均衡，多吃蔬菜水果，避免不健康的"高热量、高脂肪"饮食习惯；⑶适量运动，避免久坐，控制体重；⑷建议女性最好能在适当的年龄结婚生育，并在产后坚持哺乳；少接触和使用含雌激素的相关药品或产品（包括保健品、化妆品等）；避免长期大量口服避孕药；⑸保持良好心态，避免精神抑郁和过度紧张。
                            </div>
                            <div class="title-text">4  宫颈糜烂</div>
                            <div class="tw-title-text">
                                【疾病解释】：是慢性宫颈炎中最常见的病变，根椐糜烂面积大小分为轻、中、重度（Ⅰ°、Ⅱ°、Ⅲ°）。根据深度又分为单纯性、颗粒状、乳头状三种。症状：白带增多呈黄色或脓性，覆盖糜烂面。病理检查排除恶性病变可考虑药物及电灼治疗。宫颈糜烂是慢性宫颈炎中最常见的病变，但极少数可发展为宫颈癌，因此必需定期作宫颈防癌检查。如细胞有异常，必需作进一步检查。宫颈糜烂轻度者，可随访观察，中及重度者物理治疗，效果较好。
                            </div>
                            <div class="tw-title-text">
                                【治疗建议】：建议定期复查妇科，如有症状，请您到妇科门诊就诊。
                            </div>
                            <div class="title-text"> 5   子宫肌瘤</div>
                            <div class="tw-title-text">
                                【疾病解释】：为女性生殖器中最常见的良性肿瘤，确切病因尚不明了。可能与女性激素有关。肌瘤小且无症状可不处理，随访观察，定期复查。如有症状，或肌瘤较大、生长过快，可手术治疗。
                                【治疗建议】：建议定期复查妇科彩超，如有异常，请您到妇科门诊进一步咨询和诊治。
                                【生活指导】：女性生殖器中最常见的良性肿瘤，肌瘤可随访观察，定期复查，其间不能口服外源性雌孕激素。如随访期间，肌瘤增大迅速，症状明显，伴继发贫血等，需妇科诊治。
                            </div>
                            <div class="title-text">  6   盆腔积液</div>
                            <div class="tw-title-text">
                                腹水、血液或脓液积聚在盆腔称之为盆腔积液。 按病理因素可分为生理性盆腔积液和病理性盆腔积液两种。病理性盆腔积液多发生在宫外孕、盆腔脏器炎症、肿瘤或外伤时；排卵后有时也能发现少量积液(由于卵泡破裂)，这属于生理性盆腔积液，通常生理性盆腔积液不需要治疗。您可以结合自身症状到妇科门诊进一步咨询诊治。
                            </div>
                            <div class="title-text">7   乳腺钙化：</div>
                            <div class="tw-title-text">
                                乳腺钙化是乳房组织内的钙质沉积，乳腺X线检查是目前发现乳腺钙化最敏感的技术。如果存在乳腺钙化，乳腺X线检查会表现为高密度影，呈现为小白点状改变。乳腺钙化根据病变性质可分为良性钙化及恶性钙化。大多数乳腺钙化是良性钙化，主要由乳腺囊肿、乳腺纤维腺瘤、乳腺导管扩张症等引起；恶性钙化主要是肿瘤坏死造成的，需要进行活检明确诊断。
                            </div>
                            <div class="title-text">8   HPV阳性</div>
                            <div class="tw-title-text">
                                高危型乳头瘤病毒（HPV）持续感染是引起宫颈癌的必要条件，感染了HPV并不代表患了宫颈癌。如果HPV16/18分型阳性，建议宫颈病门诊就诊，预约阴道镜；如果HPV其他高危分型阳性，有条件的建议结合宫颈细胞学涂片检查，根据细胞学结果进行进一步治疗；如果HPV其他高危分型阳性，无条件进行宫颈细胞学检查的，建议半年后复查。
                            </div>
                            <div class="title-text">9   附件区囊性肿物</div>
                            <div class="tw-title-text">
                                在女性生殖器官中，输卵管和卵巢被称为附件。附件囊肿指发生于输卵管或卵巢的占位性病变，目前尚无鉴别附件良恶性的有效指标，对于绝经后女性常规Ca-125评估。少数附件囊肿因扭转、嵌顿、破裂或感染表现出急腹症而行手术，多数附件囊肿无明显的症状；对于直径＞5cm的囊肿应结合临床、年龄、高级影像学检查及Ca-125综合评估决定手术或其他治疗。建议妇科就诊进一步咨询诊治。
                            </div>
                            <div class="title-text">10  阴道炎：
                            </div>
                            <div class="tw-title-text">
                                疾病解释】：正常情况下阴道内也存在各种细菌以及偶见霉菌，但由于乳酸杆菌有自净作用，可不发病，而当此种情况受到破坏时，则病原体趁机而起引起炎症。通常阴道炎有：细菌性阴道炎、滴虫性阴道炎、念珠菌性阴道炎、老年性阴道炎，共有的症状是阴道分泌物增多伴外阴的不适或灼热或搔痒，程度视不同的病原体而不同，妇科化验证实病原菌后药物治疗。
                                【治疗建议】：根据您的情况还需到妇科门诊进一步咨询诊治。
                                【生活指导】：平日要注意个人卫生，经常清洗外阴，不用公共的毛巾、浴具。

                            </div>
                            <div class="title-text">
                                11  子宫结节
                            </div>
                            <div class="tw-title-text">
                                子宫结节提示的可能是子宫有炎症或者是小肌瘤，子宫结节还要考虑具体位置是在子宫浆膜层，还是黏膜层或者是子宫内膜层，如果子宫内膜有结节，还要考虑是否有子宫内膜息肉和子宫内膜炎症导致。这种情况需要做宫腔镜检查才能发现小的内膜息肉。如果子宫浆膜层和肌层的小结节，提示考虑是慢性的子宫肌层的炎症、纤维化或钙化导致的，也有可能是肌瘤术后形成的瘢痕增生。如果子宫小结节没有影响月经周期，没有影响月经量，可以不用处理，每年妇科定期检查着，只要体积不增大不用太担心。
                            </div>
                            <div class="title-text">12  乳腺导管扩张
                            </div>
                            <div class="tw-title-text">
                                B超检查提示乳腺导管扩张，如果只是单纯的导管扩张，而没有不适症状，可以不做治疗，定期复查。如果出现乳头溢液，局部疼痛、肿块等症状，建议乳腺外科门诊就诊。
                            </div>
                            <div class="title-text">13   宫颈息肉：
                            </div>
                            <div class="tw-title-text">
                                【疾病解释】慢性炎症长期刺激，使颈管腺体和间质的局限性增生，并向宫颈管外口突出形成息肉。检查可见宫颈息肉通常为单个，也可为多个，红色，呈舌形，质软而脆，易出血。子宫颈息肉极少恶变，但应与子宫的恶性肿瘤鉴别。宫颈息肉可行息肉摘除术治疗，较小息肉，也可定期复查。
                                【治疗建议】请您到妇科门诊进一步咨询诊治。
                            </div>
                            <div class="title-text">
                                14   子宫肌层回声不均匀
                            </div>
                            <div class="tw-title-text">
                                正常情况下，B超检查子宫肌层回声应该是均匀的，这是符合正常子宫肌层的回声。子宫肌层回声不均匀，在临床上常常是因为以下原因造成的：1、在肌层有肌瘤的情况下，通过B超检查会发现肌层回声不均匀，临床上通过B超检查可以做出初步的诊断；2、子宫腺肌症，由于腺肌症的病人在月经期的时候，移位在子宫肌层的子宫内膜会出现出血的情况，通过B超检查的时候会发现肌层有液性暗区，在临床上会表现为痛经、子宫增大，并且还可能会出现不孕不育。另外，B超检查子宫肌层回声不均，要结合临床病史，进行综合性的分析，必要的时候要定期的复查B超。
                            </div>
                            <div >
                                <div class="title" style="padding-top: 100px;font-size: 30px;font-weight: bold">结 语</div>

                                <div class="tw-title-text">
                                    大庆油田总医院健康管理中心是医院的二级临床科室，2017年被授予“全国健康管理示范基地”。中心以医院为依托，主要开展“健康体检、健康管理（检后评估、促进、干预、咨询保健服务）”等服务工作，以“增加内涵，健康全民”为发展目标，崇尚“预防为主、治疗为辅”的健康管理理念，以先进的仪器设备、精湛的医学技术、优秀的专家团队、成熟的健康体检和健康管理软件为基础，满足全民高品质、多层次、多样化的健康需求。如您有任何需要，请电洽相关部门，咨询相关事宜。
                                </div>
                                <div class="tw-title-text">
                                    定期健康检查对于员工健康的重要性相信您已知道，然而，更重要的是完成健康检查后，改善健康活动的开始，也就是健康促进的一级预防医学（适当的运动、均衡的饮食、身心休闲），这才是健康检查最终的目标。
                                </div>
                                <div class="title" style="text-align: center;padding-top: 50px;font-size: 30px;font-weight: bold">
                                    关注身体健康，是我们共同的目标！
                                </div>
                            </div>


                        </div>
                    </div>
                </el-row>

            </div>
        </div>
        <div class="buttons">
            <el-button class="btn" @click="handleClose()">取 消</el-button>
            <el-button class="btn" type="primary" @click="handelprint">打印报告</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import nvBar from './widgets/nvBar'
    import yichangBar from './widgets/yichangBar'

    export default {

        name: 'jiankangtijain',
        components: {
            yichangBar,
            nvBar
        },
        props: {
            visible: {
                default: false
            },
        },
        data() {
            return {
                form: {
                },
                print: {
                    id: 'printTest',
                },
                printFooter: null,
            }
        },
        methods: {
            handleClose() {
                this.$emit('close')
            },
            handelprint(){
                this.$print(this.$refs.print)
            },
        }
    }
</script>

<style lang="scss" scoped>
    .text-title {
        font-weight: bold;
        font-size: 80px;
        text-align: center;
        letter-spacing: 30px;
        color: black;
    }

    .imags-logo {
        display: flex;
        justify-content: left;
        padding-bottom: 60px
    }

    .imags {
        display: flex;
        justify-content: center;
        padding: 10px 0;

        img {
            height: 350px;
            width: 600px;
        }
    }


    .tjp {
        padding-top: 50px;
        color: black;
        font-weight: bold;
        text-align: center;
        font-size: 30px;
        letter-spacing: 3px;

        div {
            padding: 10px;
        }
    }

    .text-sx {
        font-size: 13px;
        padding: 4px 0;
    }


    .twopage {
        display: flex;
        align-items: center;
        color: black;
        padding: 0px 10px 10px;
        line-height: 40px;
        font-size: 19px;

        .title {
            font-size: 23px;
            font-weight: 600;
            color: #339966;
        }

        .tw-title-text {
            padding: 10px 0px;
            text-indent: 40px;
        }

        .qr {
            padding-top: 20px;

            .title-text {
                font-size: 20px;
                font-weight: 600;
            }

            text-align: center;

            img {
                width: 200px;
            }
        }
    }


    .three-page, .four-page {
        .title-text {
            font-size: 25px;
            font-weight: bold;
        }
    }

    table {
        border-collapse: collapse;
    }

    table tbody tr {
        line-height: normal !important;
        font-size: 14px;
    }

    table tbody td {
        padding: 5px;
        border: 1px solid black;
        text-align: center;
    }

    table tbody th {
        padding: 5px;
        background: #DDEBF7;
        border: 1px solid black;
        text-align: center;
    }

    .page-footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        border-top: 1.5px solid black;
        text-align: center;
        z-index: 2; /* 底部内容的层叠顺序较低于页面内容 */
    }

    @media print {
        @page {
            margin-bottom: 30px; /* 在每一页的四周留出30px的边距 */
        }
    }

</style>

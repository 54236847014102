<template>
    <el-dialog :visible="visible" width="800px" class="dialog" @close="handleClose">
        <div style="max-height: 400px;overflow: auto">
            <div ref="print" style="padding: 20px;letter-spacing: 1px;font-family: 'SimSun', '宋体', Arial, sans-serif;"
                 id="printTest">
                <el-row style="page-break-after:always">
                    <div class="imags-logo">
                        <img src="../../../assets/logo.jpg">
                    </div>
                    <div class="text-title">团检报告</div>
                    <div class="imags">
                        <img src="../../../assets/title.jpg">
                    </div>
                    <el-row class="tjp">
                        <div>参检单位名称: 公司职工健康体检</div>
                        <div style="padding-left: 20px">体检日期： 二○二三年度</div>
                        <div>体检预约电话：5804094</div>
                        <div>报告咨询电话：5805005</div>
                    </el-row>
                </el-row>
                <el-row class="twopage" style="page-break-after:always">
                    <div>
                        <div class="title">
                            前 言
                        </div>
                        <div class="tw-title-text">
                            这是一份针对贵单位职工的专项体检结果综合分析报告，希望透过本报告，能够了解职工的身体健康状况及改善方向
                        </div>
                        <div class="tw-title-text">
                            这份报告包括三部分：第一部分，我们对贵单位参检职工的整体情况进行了分析，分析了年龄分布情况，重要指标的结果分布；第二部分体检异常情况统计，可以了解到各种疾病在贵单位职工群体中的发病情况及所占比率，职工的平均健康水平：第三部分我们根据贵单位职工体检出现的常见疾病给出了健康建议，以便指导今后如何改善健康状况，指导职工采取相应的促进活动
                        </div>
                        <div class="tw-title-text">
                            大庆油田总医院体检中心引进全国先进的全自动化体检系统（体检软件和健康管理软件），实现所有体检数据自动传输和终身保存，实现检后亚健康人群的疾病风险评估、健康促进及膳食、运动、心理等全方位健康干预。并针对团体体检结果做出“统计分析”后，中心将提供健康教育与改善方案、健康计划实施追踪等；并针对健康问题多及疾病风险高的疾病，组织我院知名临床医生进行专场咨询讲解。
                        </div>
                        <div class="tw-title-text">
                            体检结果可通过手机查询，方便快捷，详情请关注公众号！
                        </div>
                        <div class="qr">
                            <div class="title-text">
                                大庆油田总医院公众号：dqytzyy
                            </div>
                            <div>
                                <img src="../../../assets/qr.jpg">
                            </div>
                        </div>
                    </div>
                </el-row>
                <el-row class="three-page twopage" style="page-break-after:always">
                    <div>
                        <div class="title-text">一、综合体检分析表</div>
                        <div>（一）年龄及性别分布</div>
                        <div class="tw-title-text">
                            此次贵单位共参检人员共***人，其中男职工***人，女职工***人。
                            参检人员平均年龄为**岁，其中男性为**岁，女性为**岁；
                            21~30岁、31~40岁、41~50岁、51~60各年龄段占总体检人数的**%、**%、**%、**%；本次体检以**岁人员为主。
                        </div>
                        <div style="padding-top: 60px">
                            <table border="0" cellspacing="0" cellpadding="0" width="100%"
                                   style="margin: auto!important;">
                                <tbody>
                                <tr>
                                    <th>年龄段</th>
                                    <th colspan="2">21-30</th>
                                    <th colspan="2">31-40</th>
                                    <th colspan="2">41-50</th>
                                    <th colspan="2">51-60</th>
                                    <th width="300px" rowspan="2">合计人数</th>
                                </tr>
                                <tr>
                                    <td width="280px"></td>
                                    <td width="200px">人数</td>
                                    <td width="200px">百分比</td>
                                    <td width="200px">人数</td>
                                    <td width="200px">百分比</td>
                                    <td width="200px">人数</td>
                                    <td width="200px">百分比</td>
                                    <td width="200px">人数</td>
                                    <td width="200px">百分比</td>
                                </tr>
                                <tr v-for="(item,index) in form.tableData" :key="index">
                                    <td>{{item.type}}</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <age-bar :bardata="[]"></age-bar>
                        </div>
                    </div>
                </el-row>
                <el-row class="four-page twopage" style="page-break-after:always">
                    <div>
                        <div>（二）重要指标检查结果分布</div>
                        <div class="tw-title-text" style="text-indent:0px">
                            1、血压：<br>
                            收缩压和舒张压的分类主要参考高血压防治指南中血压水平的分级，正常：90mmHg≤收缩压<140mmHg、60mmHg≤舒张压<90mmHg；
                            偏高 ：收缩压≥140mmHg 和/或 舒张压≥90mmHg。
                            参检人员中血压偏高的比例为男性参检人员占比*** %，女性占比**** %
                            男性异常比率明显高于女性。
                        </div>
                        <div style="padding-top: 60px">
                            <table border="0" cellspacing="0" cellpadding="0" width="100%"
                                   style="margin: auto!important;">
                                <tbody>
                                <tr>
                                    <th>检验项目</th>
                                    <th>性别</th>
                                    <th>偏高例数</th>
                                    <th>所占百分比</th>
                                </tr>
                                <tr>
                                    <td width="280px" rowspan="2">血压</td>
                                    <td width="200px">男</td>
                                    <td width="200px"></td>
                                    <td width="200px"></td>
                                </tr>
                                <tr>
                                    <td>女</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <xue-bar :bardata="[]"></xue-bar>
                        </div>
                        <div>
                            <div class="tw-title-text" style="text-indent:0px">
                                2、空腹血糖：<br>

                                空腹血糖的参考范围为：
                                偏高：空腹血糖>6.10mmol/L。
                                参检人员中空腹血糖与参考范围相比偏高的比例为男性参检人员 *** % ，女性参检人员 *** %
                                男性异常比率明显高于女性。
                            </div>
                        </div>
                        <div style="padding-top: 60px">
                            <table border="0" cellspacing="0" cellpadding="0" width="100%"
                                   style="margin: auto!important;">
                                <tbody>
                                <tr>
                                    <th>检验项目</th>
                                    <th>性别</th>
                                    <th>偏高例数</th>
                                    <th>所占百分比</th>
                                </tr>
                                <tr>
                                    <td width="280px" rowspan="2">血压</td>
                                    <td width="200px">男</td>
                                    <td width="200px"></td>
                                    <td width="200px"></td>
                                </tr>
                                <tr>
                                    <td>女</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <xue-bar :bardata="[]"></xue-bar>
                        </div>
                        <div>
                            <div class="tw-title-text" style="text-indent:0px">
                                3、血脂<br>
                                血脂的参考范围：
                                偏高：总胆固醇≧5.18mmol/L，
                                甘油三酯≧1.7mmol/L，
                                低密度脂蛋白胆固醇≧130mg/dl。
                                参检人员中血脂与参考范围相比偏高的比例为男性参检人员 *** % ，女性参检人员 *** %
                                男性异常比率明显高于女性。

                            </div>
                        </div>
                        <div style="padding-top: 60px">
                            <table border="0" cellspacing="0" cellpadding="0" width="100%"
                                   style="margin: auto!important;">
                                <tbody>
                                <tr>
                                    <th>检验项目</th>
                                    <th>性别</th>
                                    <th>偏高例数</th>
                                    <th>所占百分比</th>
                                </tr>
                                <tr>
                                    <td width="280px" rowspan="2">血压</td>
                                    <td width="200px">男</td>
                                    <td width="200px"></td>
                                    <td width="200px"></td>
                                </tr>
                                <tr>
                                    <td>女</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <xue-bar :bardata="[]"></xue-bar>
                        </div>
                        <div>
                            <div class="tw-title-text" style="text-indent:0px">
                                4、尿酸：<br>
                                血尿酸的参考范围：
                                偏高：男性≧428μmol/L， 女性≧357μmol/L，
                                参检人员中血尿酸与参考范围相比偏高的比例为男性参检人员 *** % ，女性参检人员 *** %
                                男性异常比率明显高于女性。

                            </div>
                        </div>
                        <div style="padding-top: 60px">
                            <table border="0" cellspacing="0" cellpadding="0" width="100%"
                                   style="margin: auto!important;">
                                <tbody>
                                <tr>
                                    <th>检验项目</th>
                                    <th>性别</th>
                                    <th>偏高例数</th>
                                    <th>所占百分比</th>
                                </tr>
                                <tr>
                                    <td width="280px" rowspan="2">血压</td>
                                    <td width="200px">男</td>
                                    <td width="200px"></td>
                                    <td width="200px"></td>
                                </tr>
                                <tr>
                                    <td>女</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <xue-bar :bardata="[]"></xue-bar>
                        </div>
                        <div>
                            <div class="title-text">二、职工健康体检具体检出具体异常例数及占比</div>
                            <div class="tw-title-text" style="text-indent:0px">
                                贵单位本年度健康体检参检职工总数 ***人，已完成体检 ***人，完成率*** %，发现异常人数 *** 人，异常率*** %。其中已完成体检中男职工***人，女职工***人。
                                <div v-for="(time,index) in form.tData" :key="index">
                                    <div>{{time.title}}</div>
                                    <div>
                                        <div style="padding-top: 60px">
                                            <table border="0" cellspacing="0" cellpadding="0" width="100%"
                                                   style="margin: auto!important;">
                                                <tbody>
                                                <tr>
                                                    <th>序号</th>
                                                    <th>年龄段</th>
                                                    <th colspan="2">21-30</th>
                                                    <th colspan="2">31-40</th>
                                                    <th colspan="2">41-50</th>
                                                    <th colspan="2">51-60</th>
                                                    <th width="300px" rowspan="2">合计</th>
                                                    <th width="300px" rowspan="2">占总人数比例</th>
                                                </tr>
                                                <tr>
                                                    <td width="100px"></td>
                                                    <td width="280px">异常</td>
                                                    <td width="200px">人数</td>
                                                    <td width="200px">占该年龄段比例</td>
                                                    <td width="200px">人数</td>
                                                    <td width="200px">占该年龄段比例</td>
                                                    <td width="200px">人数</td>
                                                    <td width="200px">占该年龄段比例</td>
                                                    <td width="200px">人数</td>
                                                    <td width="200px">占该年龄段比例</td>
                                                </tr>
                                                <tr v-for="(item,index) in form.tableData" :key="index">
                                                    <td>{{index+1}}</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <yichang-bar :bardata="[]"></yichang-bar>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div>
                            <div class="title-text">三、 常见疾病解释和健康建议</div>
                            <div class="title-text">1、  血脂异常</div>
                            <div class="tw-title-text">
                                【疾病解释】血脂异常通常指血清中胆固醇和甘油三酯水平升高，俗称高脂血症。实际上高脂血症也泛指包括低HDL-C血症在内的各种血脂异常。当人体内由于各种因素导致血脂多于机体需要时，胆固醇等就会在血管壁上沉积，逐渐引起血管硬化和狭窄。高脂血症的主要危害是导致动脉粥样硬化，进而导致众多的相关疾病，其中最常见的一种致命疾病就是冠心病。严重高甘油三酯血症还可诱发急性胰腺炎，是另一种致命性疾病。高脂血症的危害是隐匿、逐渐、进行性和全身性的。
                            </div>
                            <div class="tw-title-text">
                                【健康建议】通过饮食控制，可以使血浆胆固醇水平下降10%-15%，同时有助于减肥。肥胖者体重减轻后，血脂紊乱亦可恢复正常。体育运动不仅可以减轻体重，而且还降低胆固醇和甘油三酯水平，升高HDL-C水平。饮食与运动治疗3个月后复查血脂水平，如果达标继续治疗；如果血脂不达标，建议心内科就诊在专科医生指导下开始药物治疗。
                            </div>
                            <div class="tw-title-text">
                                【生活指导】
                                ①肥胖者需要控制饮食，减轻体重。
                                ②饮食以低脂、低胆固醇食物为宜，如瘦肉、鱼，少吃动物内脏，肥肉、黄油、鸡蛋等。
                                ③多吃富含纤维素的蔬菜水果，少吃盐和糖。
                                ④进食要有规律，忌暴饮暴食。忌烟酒。
                                ⑤积极治疗原发病，如糖尿病、胆结石等。
                                ⑥适当体育锻炼对防治高脂血症有极大作用，如慢跑、太极、瑜伽等。
                            </div>
                            <div class="title-text">2、  脂肪肝</div>
                            <div class="tw-title-text">
                                【疾病解释】脂肪肝是由于体内过多的脂肪沉积在肝脏所致。脂肪肝不一定影响肝功能，轻者无症状。脂肪肝具有可逆性，大多数经采取低脂饮食、适度运动和限制饮酒而减轻，必要时可药物治疗。
                            </div>
                            <div class="tw-title-text">
                                【治疗建议】建议定期做B超追踪观察。
                            </div>
                            <div class="tw-title-text">
                                【生活指导】治疗脂肪肝须控制饮食，适当减轻体重，低脂饮食的同时应注意饮食营养的合理搭配，同时还应坚持体育锻练，适当进行一些慢步跑、快步走等运动，以消耗体内热量。
                            </div>
                            <div class="title-text">3、  血压偏高</div>
                            <div class="tw-title-text">
                                【疾病解释】高血压定义为多次重复测量后诊室收缩压≥140mm Hg和／或诊室舒张压≥90 mm Hg。
                            </div>
                            <div class="tw-title-text">
                                【健康建议】诊断高血压，应该进行全面检查与评估，包括病史调查、体格检查、实验室检查、遗传学分析、血压测量和靶器官损害评估。对大多数高血压患者而言，应根据病情，在4周或12周内将血压逐渐降至目标水平。在改善生活方式的基础上，血压仍超过140/90mmHg,和（或）目标水平的应给与药物治疗。应根据自身情况结合临床心内科就诊制定降压方案。
                            </div>
                            <div class="tw-title-text">
                                【生活指导】对于有高血压家族史或高危因素的人，预防高血压尤为重要，预防措施主要为保持健康的生活方式。
                            </div>
                            <div class="tw-title-text">
                                （一）运动可以改善血压水平。非高血压人群以及血压稳定控制在目标水平的患者，每周至少应锻炼5天，包括散步、慢跑、游泳或骑自行车中的任一项，每周应进行至少150分钟的中度有氧运动和75分的强化运动。高危患者在运动前应进行专业评估。
                            </div>
                            <div class="tw-title-text">
                                （二）注意减压或避免压力。
                            </div>
                            <div class="tw-title-text">
                                （三）戒烟。吸烟会升高血压，戒烟可降低患高血压、心脏病和其他代谢性疾病的风险。
                            </div>
                            <div class="tw-title-text">
                                （四）健康饮食。应避免大量摄入酒精、药物以及高盐、高糖、高脂饮食。
                            </div>
                            <div class="title-text">4、   肝功能异常</div>
                            <div class="tw-title-text">
                                【疾病解释】：肝功异常可由病毒感染、药物、酒精、原虫、细菌等可导致肝脏损害的原因引起。脂肪肝、肝淤血、肝肿瘤等也可引起肝功能异常。肝脏损害可分为急性和慢性，急性期肝损害可一过性、可迁延不愈，或转为慢性。超声可见肝区光点粗大、回声强、分布不均等。应根据其病因、临床表现及相关检查进行鉴别。
                            </div>
                            <div class="tw-title-text">
                                【治疗建议】：根据您的情况，需到消化内科就诊。
                            </div>
                            <div class="title-text"> 5、   甲状腺结节</div>
                            <div class="tw-title-text">
                                【疾病解释】甲状腺结节是甲状腺细胞异常增生后在甲状腺组织内出现的团块。甲状腺结节可以是一个，也可以是多个，结节的质地可以是实性的（结节内部为固体组织），也可能是囊性的（结节内部充盈着液体）。单纯的甲状腺结节恶性率很低，恶性病变仅约占2-5％，所以体检查出来有结节不用惊慌。但是一定要定期随访复查，一般来说，6-12个月要复查甲状腺超声、血清甲状腺激素及抗体等指标，以防病情发展和变化。
                                【健康建议】得了甲状腺结节，要看结节的大小，如果结节比较大，如大于4CM以上，要进行手术切除。如果结节较小且超声提示TI-RADS分级＜3级者，要定期到医院复查，一般三个月或半年结节没有增大，则没有什么大的问题，如果增大明显或超声提示TI-RADS分级≥4级则要让医生做进一步的检查和治疗。
                                【生活指导】甲状腺结节患者应注意调整情绪，长时间过度劳累会加重甲状腺的负担，降低机体免疫力。饮食中的碘元素对甲状腺的影响最大，摄碘不足或过多都会引起甲状腺病变。一般来说，成年人每日摄入100-200微克的碘就够了。在日常饮食中，甲状腺结节饮食注意忌烟、酒，忌肥腻、油煎食物，忌辛辣刺激性食物，如花椒、辣椒、葱、桂皮等，宜要多吃一些有利于消结散肿的食物，如芥菜，油菜，猕猴桃等。还要多吃一些具有增强免疫力的食物，如，香菇，木耳，红枣、山药，新鲜的水果等。
                            </div>
                            <div class="title-text">  6、 糖尿病</div>
                            <div class="tw-title-text">
                                【疾病解释】糖尿病是一组以高血糖为特征的代谢性疾病，糖尿病长期存在的高血糖导致各种组织，特别是眼、肾、心脏、血管神经的慢性损害。糖尿病存在家族发病倾向，1/4～1/2患者有糖尿病家族史。糖尿病是一种生活方式病，平时的时候不注意控制饮食，大吃大喝、不运动，容易导致营养过剩，促使胰岛每天都在过剩工作，久而久之，导致血糖不能正常控制。
                                【健康建议】严格控制饮食及总热量，长期适量运动，合理应用降糖药，动态监测血糖，积极防治并发症。饮食方面应注意原则，高纤维素、高蛋白、低脂肪、忌糖。定期监测空腹血糖及餐后2小时血糖变化，内分泌科就诊。
                                【生活指导】糖尿病的饮食控制：
                                一、吃动平衡，达到或维持健康体重
                                二、主食定量，粗细搭配，全谷物，豆类占1/3
                                三、多吃蔬菜，水果适量，种类、颜色多样
                                四、常吃鱼禽，蛋类和畜类适量，限制加工肉类
                                五、奶类豆类天天有，零食加餐合理选择
                                六、清淡饮食，足量饮水，限制饮酒
                                七、定时定量，细嚼慢咽，注意先蔬菜再肉类后主食的进餐顺序
                                八、注重自我管理，定期接受个体化营养指导。
                            </div>
                            <div class="title-text">  7、  尿酸偏高</div>
                            <div class="tw-title-text">
                                【疾病解释】尿酸是检测肾功能的重要指标之一，轻度尿酸增高多因高蛋白饮食所致，如海鲜、肉类、鱼类食物等，一般不需要治疗；明显增高则可导致痛风、痛风性肾病等。偶尔一次检查发现尿酸增高，不一定有临床意义，可以复查，如持续增高，应引起重视。
                                【治疗建议】建议定期复查血尿酸，必要时到内科门诊就诊。
                                【生活指导】(1)低嘌啉饮食,少吃动物内脏(肝.肾.骨髓)及沙丁鱼、蟹、虾、菠菜、大肠、香肠等,低脂饮食。(2)偏碱性饮食,多进食蔬菜等;忌酸性食物,忌醋。(3)不喝酒,多饮水、饮茶。(4)若出现关节酸痛,内科门诊治疗。
                            </div>
                            <div class="title-text">8、动脉硬化</div>
                            <div class="tw-title-text">
                                动脉硬化症，是动脉的一种炎症性病变，可使动脉管壁增厚、变硬，失去弹性、管腔狭小。动脉硬化是随着人年龄增长而出现的血管疾病，其规律通常是在青少年时期发生，至中老年时期加重、发病。
                                健康建议：1、减少对脂肪的摄取:应少食"饱和脂肪酸"占有量较多的煎炸食物及含"高胆固醇"食物的虾、肝、肾和其他内脏，蛋黄等。
                                2、不吸烟并防被动吸烟:烟草毒害心血管内皮细胞，损害内皮系统功能，可致心肌肥大、变厚，殃及正常的舒缩运动并可致"好"血脂HDL下降。
                                3、坚持适量的体力活动:体力活动量需根据原本身体情况而定，要循序渐进，不宜勉强作剧烈运动，每天最好坚持不短于30分钟的活动，可"一次性完成"或分3次进行，每次10分钟。 依个体条件进行跳绳、保健体操、打太极拳、骑车、步行、修花剪草、拖地、干家务等。
                                4、释放压抑或紧张情绪，有利于紧张的血管壁舒缓，延缓动脉硬化的发生。
                            </div>
                            <div class="title-text">9、 T14呼气试验阳性</div>
                            <div class="tw-title-text">
                                【疾病解释】幽门螺杆菌（Hp）可引起多种胃病，包括胃炎、胃溃疡、十二指肠溃疡、非溃疡性消化不良、胃癌等。感染幽门螺旋杆菌后，主要症状表现在反酸、烧心、以及胃痛、口臭。67%~80%的胃溃疡和95%的十二指肠溃疡，都是由幽门螺旋杆菌引起的。世界卫生组织将幽门螺旋杆菌定位在第一致癌因素，因此，根除幽门螺杆菌已经成为现代消化道疾病治疗的重要措施。碳14呼气试验检测幽门螺旋杆菌具有无痛、无创、快速简便、无交叉感染的优点，被国内外专家一致推荐为诊断Hp的金标准，在临床上已被广泛推广应用。
                                【健康建议】不推荐所有感染幽门螺旋杆菌的人，都采取根治治疗。大多数权威医生建议，以下情况需要采取根治治疗：既往患有消化性溃疡、患有胃粘膜相关淋巴组织淋巴瘤、有胃癌家族史、慢性胃炎伴有消化道症状，其他治疗方案无效、慢性胃炎伴有胃粘膜萎缩或糜烂的、长期服用质子泵抑制剂的、胃癌术后、原因不明的缺铁性的贫血、特发性血小板减少性紫癫的患者、患心血管疾病，需要长期服用低剂量阿司匹林患者、发生增生性胃息肉的患者。
                                建议您结合自身病史及临床症状，消化内科就诊进一步咨询是否需要根除幽门螺杆菌。
                                【生活指导】
                                一、避免家庭性感染  预防幽门螺旋杆菌，应避免家庭性感染。家庭成员之间使用的餐具、生活用品最好分开使用。父母感染给子女的概率比较高。
                                二、保持口腔健康   幽门螺旋杆菌感染者一般有口臭等口腔问题。除了早晚坚持刷牙以外，要及时的清理牙菌斑等口腔问题，这为预防幽门螺旋杆菌奠定了基础。
                                三、避免喝生水、吃生的食物。 经研究证实，幽门螺旋杆菌可以在自来水中存活4-10天，在河水中存活长达三年。预防的要素就是避免喝生水，吃生的食物。
                                四、餐具定期消毒   使用的餐具器皿，除了要定期消毒以外，刮痕严重的餐具也要及时更换。消毒柜的高温杀菌达到125度，就能够杀死大部分的病菌。餐具最好分开使用，避免病从口入，影响健康。
                            </div>
                            <div class="title-text"> 10、  肝囊肿、肾囊肿及肝脏血管瘤
                            </div>
                            <div class="tw-title-text">
                                【疾病解释】：是常见的肝脏、肾脏的良性病灶，其内为良性水囊结构，可单个或数个发生。多为先天性，且不会恶变。通常没有症状，也不影响肝功能或肾功能。有时会随年龄增长而增大，大多不需要进一步处理和治疗。
                                【治疗建议】：建议定期复查B超，如有症状，请您到普外科门诊就诊。
                            </div>
                            <div class="title-text">11、  不正常心电图：
                            </div>
                            <div class="tw-title-text">
                                常见的不正常心电图包括：窦性心动过缓、窦性心律不齐、偶发房性早搏、房室传导阻滞、束支传导阻滞、T波异常、ST段改变 等等，大多数心电图的轻度异常不一定代表心脏疾病，要结合临床症状、查体及其它相关检查才能确诊。像窦性心动过缓、窦性心律不齐、偶发房性早搏就常见于正常人，所以是否需要治疗一定要结合临床医生的建议。下面是两种有可能和心脏疾病有关的常见的心电图改变。
                                ①ST段轻度改变：
                                ST段轻度改变常见于心肌缺血。正常ST段应该在等电位上，有时候有一些轻微的偏移，ST段下移在R波为主的导联上不应该超过0.5mm，而ST段抬高除v1到v3导联可抬高3mm外，其余导联不应该超过1mm。ST段轻度改变的形态很重要，水平型、下倾型和低垂型意义都非常大。ST段轻度改变，除了可能是心血管疾病之外，有一些患者有可能由于过度的劳累、精神压力大，心脏神经症的人也不排除会出现类似的改变。还有一些患者虽然出现ST段轻度改变，但其实有可能并不是有临床意义的，属于非特异性的ST段轻度改变，不能通过此心电图诊断任何疾病，建议定期观察，如有不适请及时心内科就诊。
                                ② T波改变：
                                T波是心室肌负极在心电图上的表现，受供应心脏的血管、神经和心肌本身的影响。导致心电图T波改变的原因既可以是病理性变化也可以是生理性变化，既可以是缺血性因素也可以是神经性因素。常见的心电图T波改变是指T波低平、双向倒置，如伴有心前区不适可能有冠心病、高血压、糖尿病或者神经紧张所引起。因此判断T波低平是否有诊断的的价值还必须密切的结合临床其它检查结果、临床症状和体征等等才能作出正确的判断，建议还是需要做相关的检查做动态心电图和心脏超声。
                            </div>
                            <div class="title-text">12、    乙肝五项异常
                            </div>
                            <div class="tw-title-text">
                                乙肝五项各项代表的意义不同，下面是几种不同的情况：
                                【乙肝表面抗原定量阳性：】①说明感染乙肝病毒，建议行乙肝DNA定量检查,定期复查肝功。②若肝功异常,请及时到传染内科治疗;③平时避免劳累,忌大量饮酒,多进食新鲜水果.
                                【乙肝五项第2、3阳性：】：提示：既往感染HBV病毒，现在已康复，多无传染性。
                                【乙肝五项第2、3、5阳性：】血清乙肝两对半(3项阳性)表示乙肝恢复期，以前感染过乙肝，已有免疫力。建议定期复查乙肝五项,肝功能和HBV-DNA。
                                【乙肝五项第3、5阳性：】提示：①既往感染过乙肝病毒。②急性乙肝病毒感染恢复期。  ③乙肝病毒抗体出现前的窗口期。  ④少数标本仍具有传染性。 ⑤定期复查肝功和乙肝病毒DNA.
                            </div>
                            <div class="title-text">13、 肥胖：
                            </div>
                            <div class="tw-title-text">
                                【疾病解释】我国成年人肥胖的体重指数（简称BMI=体重kg/身高㎡）界值是≥28kg/㎡。肥胖是一种由多因素引起的慢性代谢性疾病，肥胖与多种慢性病密切相关，如高血压、糖尿病、冠心病、高脂血症、脂肪肝、高尿酸血症等。此外，大量科学研究表明，肥胖是多种癌症的危险因素，肥胖者癌症的发病率要远高于正常体重者。
                                【健康建议】改变生活方式，养成健康的饮食习惯、增加体育锻炼是肥胖治疗的基础。
                                （1）控制总能量摄取，限制每天的食物摄入量和摄入食物的种类，减少能量以保证人体能从事正常的活动为原则。食物选择上以水产品、瘦肉、奶等提供蛋白，少吃肥肉等富含脂肪和胆固醇的食物，增加蔬菜和谷类食物。
                                （2）增加身体活动量，坚持长期规律运动。运动量和强度应当逐渐递增，最终目标应为每周150分钟以上中等强度的有氧运动，每周运动3至5天。
                                （3）行为疗法，使用较小的餐具，按计划用餐，每餐七分饱，避免暴饮暴食；细嚼慢咽以延长进食时间，有助于减少进食量。
                                （4）在专业医生指导下使用药物减肥。
                            </div>
                            <div class="title-text">14、 胆囊息肉
                            </div>
                            <div class="tw-title-text">
                                小的息肉可以定期观察，大于1cm及多发性者，宜手术治疗，以免恶变。建议定期复查B超，肝胆外科就诊。
                            </div>
                            <div class="title-text">
                                15、 颈动脉斑块形成
                            </div>
                            <div class="tw-title-text">
                                【疾病解释】颈动脉分为三层，外膜、中层和内膜。动脉内膜与中层密切相连，正常情况下两部分厚度（简称为IMT）相加不超过1.0毫米。颈动脉内膜中层厚度超过1.2毫米时，诊断为颈动脉斑块形成；当颈动脉内膜中层厚度超过1.4毫米时，诊断为颈动脉狭窄。
                                目前认为颈动脉斑块与老年人缺血性脑卒中的发生密切相关。其引起缺血性脑卒中的机制可能为：斑块增大致颈动脉管径狭窄引起颅内低灌注及斑块脱落形成栓子，导致颅内动脉栓塞。临床上，通过对颈动脉的狭窄程度及斑块的形态学测定，来对颈动脉斑块进行评价，判断其危害性。
                                【健康建议】颈动脉斑块根据斑块形态，颈动脉狭窄程度，其危险性各不相同，并不是只要查出颈动脉斑块，就一定要服用他汀、阿司匹林等药物预防血栓的问题，是否要用药，还是要综合血脂、血压、斑块稳定性评估，颈动脉狭窄情况等综合判断。
                                对于轻度颈动脉斑块，颈动脉狭窄不严重，而其他心血管疾病风险又不大的情况，完全可以不用服药稳定斑块。但是对于心脑血管疾病风险高危的朋友，还是应该通过服用他汀药控制血脂，稳定斑块，服用阿司匹林预防血小板凝集，同时也要注意控制血压，血糖等指标，减少心脑血管疾病风险。
                                对于颈动脉有易损斑块、无症状但血管狭窄程度＞80%、有症状血管狭窄程度﹥50%的情况，建议积极采取手术治疗。建议根据自身病史和临床症状神经内科就诊制定治疗计划。
                                【生活指导】（1）戒烟戒酒。（2）避免熬夜通宵、长时间久坐等。（3）养成健康饮食习惯，避免食用高脂肪食物，如动物内脏等。（4）坚持参加低强度的有氧运动，如快走和慢跑等。（5）保持乐观的心态。（6）药物治疗。（7）定期体检复查。
                            </div>
                            <div class="title-text">
                                16、 肝脏脂肪衰减值增高
                            </div>
                            <div class="tw-title-text">
                                肝脏脂肪衰减值作为脂肪肝的参考指标，小于240为正常，240-265为轻度脂肪肝，265-295为中度脂肪肝，大于295为重度脂肪肝。测定结果需结合临床综合评估。
                                建议控制饮食，适当减轻体重，低脂饮食的同时应注意饮食营养的合理搭配，同时还应坚持体育锻练，适当进行一些慢步跑、快步走等运动，以消耗体内热量。定期复查、追踪观察。
                            </div>
                            <div class="title-text">
                                17、  尿红细胞阳性
                            </div>
                            <div class="tw-title-text">
                                【疾病解释】正常人尿液中不见或偶见红细胞，尿液中含有较多红细胞时称为血尿，其外观变化不明显，每高倍视野＞3个，称为显微镜下血尿。每升尿液中含血量＞1ml,称为肉眼血尿。
                                【健康建议】正常人剧烈活动后可以引起生理性血尿。病理性血尿常见于泌尿系统炎症（如肾小球肾炎及膀胱炎），泌尿系结石、结核、肿瘤、外伤等；也可见于血液系统疾病，如血友病、血小板减少性紫癜等。
                                首先造成检查结果不正常的情况出现的原因还是有很多的，建议结合病史及临床症状及时复查尿常规。如仍持续性阳性，泌尿内科就诊积极查找尿红细胞阳性的原因，完善红细胞的形态学分析、泌尿系超声等检查，进一步明确病因。平时应多饮水，多排尿。
                                【注意事项 】1.一般要求女性留取尿标本时应避开经期前后三天，以防止阴道分泌物混入尿液中，影响检查结果。男性留取标本应注意包皮垢影响检查，应该翻开包皮清洗干净。
                                2.检查前也应该避免剧烈运动。
                                3.检查前一天晚上，应该避免吃油腻的食物。
                            </div>
                            <div class="title-text">
                                18、 左室松弛性下降
                            </div>
                            <div class="tw-title-text">
                                心脏左室松弛性下降，是指心脏舒张功能下降，主要为高血压引起。这是心脏病变的最轻阶段，不过不必太担心，注意保持好的心情，若有高血压的话，一定要控制好血压。
                            </div>
                            <div class="title-text">
                                19、   肺结节：
                            </div>
                            <div class="tw-title-text">
                                【疾病解释】肺结节（pulmonary nodule, PN）是指肺内直径小于或等于3 cm的类圆形或不规则形病灶，影像学表现为密度增高的阴影，可单发或多发，边界 清晰或不清晰的病灶。其中直径小于1cm者称为肺小结节，直径小于0.5cm叫做微小结节。肺部结节的原因较多，有炎性假瘤、结核、真菌感染、霉菌感染、肿瘤等等诸多情况。依据结节密度将肺结节分为三类：实性结节、磨玻璃密度结节、部分实性结节。
                                【健康建议】肺结节一般无任何症状，通常在体检中偶然发现。若是一般的实性结节直径≤0.8cm，密度均匀，界限清晰，可以半年随访，随访两年若是没有变化，基本上就可以不必过分纠结担心了，以后每年体检定期复查CT。如结节直径＞1.0cm，需要到胸外科进一步检查评估。
                                【生活指导】如果已经发现了肺结节（除了炎性结节），想通过改变生活方式或者是饮食，来消除肺结节，这基本上是不可能的。如果有粉尘或者是其他职业接触的，尽量远离这些场所。吸烟者应戒烟。
                            </div>
                            <div class="title-text">
                                20、 同型半胱氨酸偏高
                            </div>
                            <div class="tw-title-text">

                                【疾病解释】动脉硬化发生的一个独立危险因子。引起血清同型半胱氨酸升高的主要原因有遗传和环境营养两种因素：代谢辅助因子如叶酸、维生素B6、B12缺乏，均可导致高同型半胱氨酸血症的发生。
                                【治疗建议】补充叶酸、B6、B12治疗高Hcy血症非常简便，只需在日常膳食中补充叶酸、维生素B6和B12通常能降低Hcy水平。对于高Hcy的人群建议少吃动物蛋白多吃植物蛋白。结合临床，心内科或神经内科就诊复查。
                            </div>
                            <div >
                                <div class="title" style="padding-top: 100px;font-size: 30px;font-weight: bold">结 语</div>

                                <div class="tw-title-text">
                                    大庆油田总医院健康管理中心是医院的二级临床科室，2017年被授予“全国健康管理示范基地”。中心以医院为依托，主要开展“健康体检、健康管理（检后评估、促进、干预、咨询保健服务）”等服务工作，以“增加内涵，健康全民”为发展目标，崇尚“预防为主、治疗为辅”的健康管理理念，以先进的仪器设备、精湛的医学技术、优秀的专家团队、成熟的健康体检和健康管理软件为基础，满足全民高品质、多层次、多样化的健康需求。如您有任何需要，请电洽相关部门，咨询相关事宜。
                                </div>
                                <div class="tw-title-text">
                                    定期健康检查对于员工健康的重要性相信您已知道，然而，更重要的是完成健康检查后，改善健康活动的开始，也就是健康促进的一级预防医学（适当的运动、均衡的饮食、身心休闲），这才是健康检查最终的目标。
                                </div>
                                <div class="title" style="text-align: center;padding-top: 50px;font-size: 30px;font-weight: bold">
                                    关注身体健康，是我们共同的目标！
                                </div>
                            </div>


                        </div>
                    </div>
                </el-row>
            </div>
        </div>
        <div class="buttons">
            <el-button class="btn" @click="handleClose()">取 消</el-button>
            <el-button class="btn" type="primary" @click="handelprint">打印报告</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import ageBar from './widgets/ageBar'
    import xueBar from './widgets/xueBar'
    import yichangBar from './widgets/yichangBar'

    export default {

        name: 'jiankangtijain',
        components: {
            ageBar,
            xueBar,
            yichangBar
        },
        props: {
            visible: {
                default: false
            },
        },
        data() {
            return {
                form: {
                    tData:[
                        {title:"1、常见异常结果按年龄分布人数及比例统计如下："},
                        {title:"2、男职工各年龄段异常人数及占比："},
                        {title:"3、女职工各年龄段异常人数及占比："},
                    ],
                    tableData: [
                        {"type": "男"},
                        {"type": "女"},
                        {"type": "合计"},
                    ]
                },
                print: {
                    id: 'printTest',
                },
                printFooter: null,
            }
        },
        methods: {
            handleClose() {
                this.$emit('close')
            },
            handelprint(){
                this.$print(this.$refs.print)
            },
        }
    }
</script>

<style lang="scss" scoped>
    .text-title {
        font-weight: bold;
        font-size: 80px;
        text-align: center;
        letter-spacing: 30px;
        color: black;
    }

    .imags-logo {
        display: flex;
        justify-content: left;
        padding-bottom: 60px
    }

    .imags {
        display: flex;
        justify-content: center;
        padding: 10px 0;

        img {
            height: 350px;
            width: 600px;
        }
    }


    .tjp {
        padding-top: 50px;
        color: black;
        font-weight: bold;
        text-align: center;
        font-size: 30px;
        letter-spacing: 3px;

        div {
            padding: 10px;
        }
    }

    .text-sx {
        font-size: 13px;
        padding: 4px 0;
    }


    .twopage {
        display: flex;
        align-items: center;
        color: black;
        padding: 0px 10px 10px;
        line-height: 40px;
        font-size: 19px;

        .title {
            font-size: 23px;
            font-weight: 600;
            color: #339966;
        }

        .tw-title-text {
            padding: 10px 0px;
            text-indent: 40px;
        }

        .qr {
            padding-top: 20px;

            .title-text {
                font-size: 20px;
                font-weight: 600;
            }

            text-align: center;

            img {
                width: 200px;
            }
        }
    }


    .three-page, .four-page {
        .title-text {
            font-size: 25px;
            font-weight: bold;
        }
    }

    table {
        border-collapse: collapse;
    }

    table tbody tr {
        line-height: normal !important;
        font-size: 14px;
    }

    table tbody td {
        padding: 5px;
        border: 1px solid black;
        text-align: center;
    }

    table tbody th {
        padding: 5px;
        background: #DDEBF7;
        border: 1px solid black;
        text-align: center;
    }

    .page-footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        border-top: 1.5px solid black;
        text-align: center;
        z-index: 2; /* 底部内容的层叠顺序较低于页面内容 */
    }

    @media print {
        @page {
            margin-bottom: 30px; /* 在每一页的四周留出30px的边距 */
        }
    }

</style>

<template>
    <div style="width:100%;padding-top: 60px">
        <v-chart autoresize :option="option" style="height: 500px" v-loading="loading"></v-chart>
    </div>
</template>

<script>
    export default {
        name: 'expertBar',
        props: {
            bardata: {
                default: []
            },
        },
        data() {
            return {
                loading: false,
                daterange: [],
                color: ['#689bd9'],
                option: {}
            }
        },
        methods: {
            getData() {
                var colors = []
                colors.push({
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: "#689bd9" // 0% 处的颜色
                    }, {
                        offset: 1, color: "#689bd9" // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                })
                this.option = {
                    title: {
                        text: '2023年度健康体检常见异常',
                        left: 'center',
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow',

                            textStyle: {
                                color: '#05294D',
                            },
                        },
                        backgroundColor: '#05294D',
                        borderColor: '#135469',
                        borderWidth: 2,
                        padding: [20, 20, 20, 20],
                    },
                    grid: {
                        borderWidth: 0,
                        top: '15%',
                        left: '20%',
                        right: '20%',
                        bottom: '15%',
                        textStyle: {
                            color: '#fff',
                        },
                    },
                    legend: {
                        show: false,
                        bottom: '-3%',
                        right: '40%',
                        textStyle: {
                            fontSize: '12px',
                            fontFamily: 'MicrosoftYaHeiUI',
                            color: '#FFFFFF',
                        },
                        itemWidth: 12,
                        itemHeight: 12,
                    },
                    xAxis: [
                        {
                            type: 'category',
                            axisLine: {
                                lineStyle: {
                                    // color: 'rgba(255,255,255,.5)',
                                },
                            },
                            splitLine: {
                                show: false,
                            },
                            axisTick: {
                                show: true,
                            },
                            splitArea: {
                                show: false,
                            },
                            axisLabel: {
                                interval: 0,
                                fontSize: 12,
                            },
                            data: [
                                "20-31",
                                "31-40",
                                "41-50",
                                "51-60",
                            ],
                        },
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            splitLine: {
                                show: true,
                                lineStyle: {
                                    color: 'black',
                                    width: 0.3
                                }
                            },
                            axisLine: {
                                show: true,
                            },
                            axisTick: {
                                show: false,
                            },
                            axisLabel: {
                                interval: 0,
                                // color: '#32ABCC',
                                fontSize: 12,
                                formatter: '{value}'
                            },
                            splitArea: {
                                show: false,
                            },
                        },
                    ],
                    series: [
                        {//柱底圆片
                            name: "",
                            yAxisIndex: 0,
                            type: "pictorialBar",
                            symbolSize: [40, 10],//调整截面形状
                            symbolOffset: [0, 5],
                            z: 12,
                            tooltip: {
                                show: true
                            },
                            itemStyle: {
                                "normal": {
                                    color: function (params) {
                                        return colors[0];
                                    }
                                }
                            },
                            label: {
                                show: true,
                                position: 'top',
                                fontSize: 14,
                                fontStyle: 'bold',
                                align: 'center',
                            },
                            data: [20, 30, 40, 50],
                        },
                        //柱体
                        {
                            name: '',
                            type: 'bar',
                            barWidth: 40,
                            yAxisIndex: 0,
                            barGap: '0%',
                            tooltip: {
                                show: false
                            },
                            itemStyle: {
                                "normal": {
                                    color: function (params) {
                                        return colors[0];
                                    }
                                }
                            },
                            data: [20, 30, 40, 50],
                        },
                        //柱顶圆片
                        {
                            name: "",
                            type: "pictorialBar",
                            yAxisIndex: 0,
                            symbolSize: [40, 10],//调整截面形状
                            symbolOffset: [0, -5],
                            z: 12,
                            tooltip: {
                                show: false
                            },
                            symbolPosition: "end",
                            "itemStyle": {
                                "normal": {
                                    color: function (params) {
                                        return colors[0];
                                    }
                                }
                            },
                            data: [20, 30, 40, 50],
                        },
                    ],
                }
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

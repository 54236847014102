<template>
    <div style="width:100%;padding-top: 60px">
        <v-chart autoresize :option="option" style="height: 360px" v-loading="loading"></v-chart>
    </div>
</template>

<script>
    export default {
        name: 'expertBar',
        props: {
            bardata: {
                default: []
            },
        },
        data() {
            return {
                loading: false,
                daterange: [],
                option: {}
            }
        },
        methods: {
            getData(){
                let yList = [40, 9];
                let colors = []
                colors.push({
                    type: 'linear',
                    x: 0,
                    x2: 1,
                    y: 0,
                    y2: 0,
                    colorStops: [
                        {
                            offset: 0,
                            color: '#53c4ca' // 最左边
                        }, {
                            offset: 0.7,
                            color: '#86eef1' // 左边的右边 颜色
                        }, {
                            offset: 0.7,
                            color: '#5ad6d9' // 右边的左边 颜色
                        }, {
                            offset: 1,
                            color: '#53c4ca'
                        }]
                })
                colors.push({
                    type: 'linear',
                    x: 0,
                    x2: 1,
                    y: 0,
                    y2: 0,
                    colorStops: [
                        {
                            offset: 0,
                            color: '#b2cc35' // 最左边
                        }, {
                            offset: 0.7,
                            color: '#F0E68C' // 左边的右边 颜色
                        }, {
                            offset: 0.7,
                            color: '#FFFACD' // 右边的左边 颜色
                        }, {
                            offset: 0.9,
                            color: '#b2cc35'
                        }]
                })
                this.option = {
                    title: {
                        text: '血压偏高例数',
                        left: 'center',
                    },
                    tooltip: {
                        trigger: 'axis',
                        formatter: "{b} : {c}",
                        axisPointer: { // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                        }
                    },
                    grid: {
                        left: '30%',
                        right: '30%',
                        top: '30%',
                        bottom: '10%',
                    },
                    //X轴
                    xAxis: {
                        type: 'category',
                        data: ['男', '女'],
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: '#7ebaf2',
                            },
                        },
                        axisTick: {
                            show: false,
                            length: 9,
                            alignWithLabel: true,
                            lineStyle: {
                                color: '#7DFFFD',
                            },
                        },
                        axisLabel: {
                            fontSize: 16,
                        },
                    },
                    yAxis: {
                        show: true,
                        splitNumber: 4,
                        axisLine: {
                            show: false,
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                type:'dashed',
                                color: '#075858'
                            },
                        },
                        axisLabel: {
                            color: '#FFFFFF',
                            margin: 30,
                            fontSize:15
                        }
                    },
                    series: [
                        {
                            type: 'bar',
                            barWidth: 60,
                            itemStyle: {
                                normal: {
                                    color: function(params) {
                                        return colors[params.dataIndex % 7];
                                    }
                                }
                            },
                            label: {
                                show: true,
                                position: 'top',
                                // position: [barWidth / 2, -(barWidth + 20)],
                                fontSize: 14,
                                fontStyle: 'bold',
                                align: 'center',
                            },
                            data: yList
                        },
                    ],
                };
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

<template>
    <el-dialog :visible="visible" width="800px" class="dialog" @close="handleClose">
        <div style="max-height: 400px;overflow: auto">
            <div ref="print" style="padding: 20px;letter-spacing: 1px;font-family: 'SimSun', '宋体', Arial, sans-serif;"
                 id="printTest">
                <el-row style="page-break-after:always">
                    <div class="imags-logo">
                        <img src="../../../assets/logo.jpg">
                    </div>
                    <div class="text-title">团检报告</div>
                    <div class="imags">
                        <img src="../../../assets/title.jpg">
                    </div>
                    <el-row class="tjp">
                        <div>参检单位名称: 公司职工专项体检 </div>
                        <div style="padding-left: 20px">体检日期： 二○二三年度</div>
                        <div>体检预约电话：5804094</div>
                        <div>报告咨询电话：5805005</div>
                    </el-row>
                </el-row>
                <el-row class="twopage" style="page-break-after:always">
                    <div>
                        <div class="title">
                            前 言
                        </div>
                        <div class="tw-title-text">
                            这是一份针对贵单位职工的专项体检结果综合分析报告，希望透过本报告，能够了解职工的身体健康状况及改善方向
                        </div>
                        <div class="tw-title-text">
                            这份报告包括三部分：第一部分，我们对贵单位参检职工的整体情况进行了分析，分析了年龄分布情况，重要指标的结果分布；第二部分体检异常情况统计，可以了解到各种疾病在贵单位职工群体中的发病情况及所占比率，职工的平均健康水平：第三部分我们根据贵单位职工体检出现的常见疾病给出了健康建议，以便指导今后如何改善健康状况，指导职工采取相应的促进活动
                        </div>
                        <div class="tw-title-text">
                            大庆油田总医院体检中心引进全国先进的全自动化体检系统（体检软件和健康管理软件），实现所有体检数据自动传输和终身保存，实现检后亚健康人群的疾病风险评估、健康促进及膳食、运动、心理等全方位健康干预。并针对团体体检结果做出“统计分析”后，中心将提供健康教育与改善方案、健康计划实施追踪等；并针对健康问题多及疾病风险高的疾病，组织我院知名临床医生进行专场咨询讲解。
                        </div>
                        <div class="tw-title-text">
                            体检结果可通过手机查询，方便快捷，详情请关注公众号！
                        </div>
                        <div class="qr">
                            <div class="title-text">
                                大庆油田总医院公众号：dqytzyy
                            </div>
                            <div>
                                <img src="../../../assets/qr.jpg">
                            </div>
                        </div>
                    </div>
                </el-row>
                <el-row class="three-page twopage" style="page-break-after:always">
                    <div>
                        <div class="title-text">一、综合体检分析表</div>
                        <div>（一）年龄及性别分布</div>
                        <div class="tw-title-text">
                            此次贵单位共参加专项体检人员共34人，其中男职工24人，女职工10人。
                            参检人员平均年龄为39.29岁，其中男性为39.43岁，女性为39.00岁；
                            30~39岁、40~49岁各年龄段占总体检人数的97.06%、2.94%；本次体检以31~40岁人员为主。
                        </div>
                        <div style="padding-top: 60px">
                            <table border="0" cellspacing="0" cellpadding="0" width="100%"
                                   style="margin: auto!important;">
                                <tbody>
                                <tr>
                                    <th>年龄段</th>
                                    <th colspan="2">31-40</th>
                                    <th colspan="2">41-50</th>
                                    <th width="300px" rowspan="2">合计人数</th>
                                </tr>
                                <tr>
                                    <td width="280px"></td>
                                    <td width="200px">人数</td>
                                    <td width="200px">百分比</td>
                                    <td width="200px">人数</td>
                                    <td width="200px">百分比</td>
                                </tr>
                                <tr v-for="(item,index) in form.tableData" :key="index">
                                    <td>{{item.type}}</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <age-bar :bardata="[]"></age-bar>
                        </div>
                    </div>
                </el-row>
                <el-row class="four-page twopage" style="page-break-after:always">
                    <div>
                        <div>（二）重要指标检查结果分布</div>
                        <div>
                            <div style="padding-top: 60px">
                                <table border="0" cellspacing="0" cellpadding="0" width="100%"
                                       style="margin: auto!important;">
                                    <tbody>
                                    <tr>
                                        <th>序号</th>
                                        <th>年龄段</th>
                                        <th colspan="2">21-30</th>
                                        <th colspan="2">31-40</th>
                                        <th colspan="2">41-50</th>
                                        <th colspan="2">51-60</th>
                                        <th width="300px" rowspan="2">合计</th>
                                        <th width="300px" rowspan="2">占总人数比例</th>
                                    </tr>
                                    <tr>
                                        <td width="100px"></td>
                                        <td width="280px">异常</td>
                                        <td width="200px">人数</td>
                                        <td width="200px">占该年龄段比例</td>
                                        <td width="200px">人数</td>
                                        <td width="200px">占该年龄段比例</td>
                                        <td width="200px">人数</td>
                                        <td width="200px">占该年龄段比例</td>
                                        <td width="200px">人数</td>
                                        <td width="200px">占该年龄段比例</td>
                                    </tr>
                                    <tr v-for="(item,index) in form.tableData" :key="index">
                                        <td>{{index+1}}</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <yichang-bar :bardata="[]"></yichang-bar>
                        </div>
                        <div>
                            <div class="title-text">二、常见疾病解释和健康建议</div>
                            <div class="title-text">1  脑梗塞：</div>
                            <div class="tw-title-text">
                                又称缺血性中风、脑卒中。由于某一支或几支脑血管的堵塞，使该血管供血区的脑组织死亡。引起堵塞的原因有二种。一种是动脉硬化，血管腔逐渐变小，在血流缓慢时血管凝血，称为脑血栓形成。另一种是脑血管的上游有东西脱落，如心腔内的栓子，主动脉或颈动脉壁上的粥样斑块，骨折后骨髓内的脂肪颗粒等等，这种情况称为脑栓塞。脑梗塞的急性期要及时到医院就诊。因脑细胞坏死后不能再生，处于恢复期的脑梗塞主要以预防复发为主。
                            </div>
                            <div class="title-text">2   冠状动脉斑块伴狭窄：</div>
                            <div class="tw-title-text">
                                因长期存在吸烟、饮酒、高血压、高血脂、高血糖等高危因素，导致冠脉形成斑块、血管狭窄或者血栓形成，程度较轻可没有症状，狭窄超过50%可于劳累或情绪激动后出现胸闷、气短等心前区不适的症状，狭窄严重时（超过75%）可导致心肌梗塞等重大疾病，故应在专科医师的指导下规律应用相关药物，去除高血压、高血脂、高血糖等危险因素，戒烟酒，适度锻炼，定期复查。
                            </div>
                            <div class="title-text">3   鼻窦炎症：</div>
                            <div class="tw-title-text">
                                鼻窦炎症临床上主要指的是各个鼻窦的炎症，包括上颌窦、筛窦、蝶窦等等。它主要是由于病毒，细菌或者真菌所引起的鼻窦黏膜的感染。它是耳鼻喉科中非常常见的一种感染，又分为急性鼻窦炎和慢性鼻窦炎，急性鼻窦炎发病率特别高，它主要是发生一些老人，小孩等抵抗力比较弱的患者，临床上多以发病比较急，患者有大量的黄脓鼻涕，鼻子不通气，可以出现发热，头疼，头晕，浑身乏力等等相关的一些症状。部分患者严重的时候甚至可以出现眶内颅内并发症。慢性鼻窦炎的话主要是由于急性鼻窦炎反复迁延不愈等等诱发所导致的，临床上主要症状是长期的鼻子不通气，长期反复的流鼻涕，嗅觉下降，伴有慢性头痛等等相关的症状为主。慢性鼻窦炎临床上早期可以用药物保守治疗，药物保守治疗没有效果的话可以考虑做鼻内镜下鼻窦手术。
                            </div>
                            <div class="title-text">4   冠状动脉肌桥：</div>
                            <div class="tw-title-text">
                                冠脉肌桥是先天发育异常，冠状动脉主干或其分支的某个节段穿行于心肌内，心脏收缩时心肌会压迫冠状动脉，造成管腔狭窄，如果狭窄明显影响到心肌供血择会出现相应的心肌缺血的临床症状。
                            </div>
                            <div class="title-text"> 5  冠状动脉钙化斑</div>
                            <div class="tw-title-text">
                                为老年退行性变，与钙代谢异常和动脉承受的压力有关。常见于50岁以上的老年人，不需要特殊治疗，可定期复查。如同时合并有高血压、高血脂、糖尿病时，需要警惕动脉粥样硬化性心血管疾病可能，应前往心内科就诊，进一步完善相关辅助检查明确诊断。平时注意饮食，多吃蔬菜和水果，适当的运动。
                            </div>
                            <div class="title-text">6   鼻窦囊肿：</div>
                            <div class="tw-title-text">
                                属瘤样病变,病因不明，可能与炎症，外伤,变态反应引起的窦口堵塞或腺管阻塞有关.无症状可定期观察。有头痛，鼻塞，多涕等表现或出现周围器官压迫症状(如眼球移位、视力下降、面部变形)时可手术治疗.X光照片及CT扫描MRI可协助诊断，确诊依靠病理活检， 您的情况还需到耳鼻咽喉科进一步咨询、诊治。
                            </div>
                            <div class="title-text">7   脑白质脱髓鞘性病变 ：</div>
                            <div class="tw-title-text">
                                脑白质脱髓鞘改变是指大脑部分神经纤维、髓鞘由于缺血、缺氧或炎症感染等引起的外层髓鞘结构的改变。脑组织由神经元细胞及其发出的神经纤维组成，髓鞘包裹在神经纤维发出的轴头外，在大脑中起绝缘作用和神经冲动的传导作用。
                                神经细胞发出的信号可以通过髓鞘向下一级神经元进行传导，髓鞘结构发生改变可以影响其正常的绝缘、传导作用，从而可以导致脑功能的异常。
                                脑白质脱髓鞘改变临床上可见于多种疾病，多发性硬化是一种以炎性脱髓鞘主要病理改变的获得性自身免疫性疾病。
                            </div>
                            <div class="title-text">8  脑缺血灶：</div>
                            <div class="tw-title-text">
                                脑缺血灶是脑内微小血管堵塞以后血流中断，它所供应的局部脑细胞因为缺血、缺氧而发生的坏死，坏死之后形成的病灶就叫做脑缺血灶。缺血灶是影像学的诊断，是头颅CT或者磁共振的报告单上的一种结论，实际上它也属于脑梗塞的一种类型。在非功能区小的、个别的缺血灶，往往没有明显临床症状，很多时候都是偶然发现的。形成缺血灶的主要原因是动脉粥样硬化，导致动脉粥样硬化的危险因素，包括高血压病、高脂血症、糖尿病、高同型半胱氨酸血症、抽烟、肥胖等等。有缺血灶就说明已经有动脉硬化的存在，就要引起重视，虽然没有症状，但是不代表没有风险。要长期服药控制这些危险因素，并且要适当戒烟，适当锻炼，还要服用抗血小板聚集的药物，以预防严重的脑梗塞发生。
                            </div>
                            <div class="title-text">9   脑室扩张</div>
                            <div class="tw-title-text">
                                脑室扩张在大多数情况下和脑萎缩有很大的关系。很多老年人都会出现一定程度的脑室扩张，这就和脑萎缩以及脑白质稀疏有关系，脑部的组织出现了萎缩的状况，使脑室出现扩张。有一些病理性的扩张是由于各种原因引起的脑室中血管破裂出血或脑室间隙阻塞，脑室内容物增多而出现的临床表现。
                            </div>
                            <div class="title-text">10   大脑中动脉狭窄
                            </div>
                            <div class="tw-title-text">
                                大脑中动脉是大脑半球的主要供血动脉之一，发生狭窄的主要原因是动脉硬化、血管内膜增厚或斑块形成，使血管内径逐渐缩小。当大脑中动脉狭窄较重甚至闭塞时，它的供血区域发生缺血，可以出现对侧面瘫、偏瘫、感觉障碍、偏盲等，如果优势半球受累还可以出现语言障碍。对于大脑中动脉的狭窄，不同的程度，治疗方式是不同的。
                                如果重度的狭窄，狭窄率在50%以上的大脑中动脉狭窄，可以考虑在病情允许的情况下，接受神经介入治疗。如果大脑中动脉的狭窄不足50%，没有形成严重的结果，就要严格抗凝抗血小板、控制血压、血脂、血糖等危险因素的治疗，同时病人方面应当戒烟限酒、规律生活、保持愉悦的心情。
                            </div>
                            <div >
                                <div class="title" style="padding-top: 100px;font-size: 30px;font-weight: bold">结 语</div>

                                <div class="tw-title-text">
                                    大庆油田总医院健康管理中心是医院的二级临床科室，2017年被授予“全国健康管理示范基地”。中心以医院为依托，主要开展“健康体检、健康管理（检后评估、促进、干预、咨询保健服务）”等服务工作，以“增加内涵，健康全民”为发展目标，崇尚“预防为主、治疗为辅”的健康管理理念，以先进的仪器设备、精湛的医学技术、优秀的专家团队、成熟的健康体检和健康管理软件为基础，满足全民高品质、多层次、多样化的健康需求。如您有任何需要，请电洽相关部门，咨询相关事宜。
                                </div>
                                <div class="tw-title-text">
                                    定期健康检查对于员工健康的重要性相信您已知道，然而，更重要的是完成健康检查后，改善健康活动的开始，也就是健康促进的一级预防医学（适当的运动、均衡的饮食、身心休闲），这才是健康检查最终的目标。
                                </div>
                                <div class="title" style="text-align: center;padding-top: 50px;font-size: 30px;font-weight: bold">
                                    关注身体健康，是我们共同的目标！
                                </div>
                            </div>


                        </div>
                    </div>
                </el-row>
            </div>
        </div>
        <div class="buttons">
            <el-button class="btn" @click="handleClose()">取 消</el-button>
            <el-button class="btn" type="primary" @click="handelprint">打印报告</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import ageBar from './widgets/agezBar'
    import yichangBar from './widgets/yichangBar'


    export default {
        name: 'jiankangtijain',
        components: {
            ageBar,
            yichangBar,
        },
        props: {
            visible: {
                default: false
            },
        },
        data() {
            return {
                form: {
                    tableData: [
                        {"type": "男"},
                        {"type": "女"},
                        {"type": "合计"},
                    ]
                },
                print: {
                    id: 'printTest',
                },
                printFooter: null,
            }
        },
        methods: {
            handleClose() {
                this.$emit('close')
            },
            handelprint(){
                this.$print(this.$refs.print)
            },
        }
    }
</script>

<style lang="scss" scoped>
    .text-title {
        font-weight: bold;
        font-size: 80px;
        text-align: center;
        letter-spacing: 30px;
        color: black;
    }

    .imags-logo {
        display: flex;
        justify-content: left;
        padding-bottom: 60px
    }

    .imags {
        display: flex;
        justify-content: center;
        padding: 10px 0;

        img {
            height: 350px;
            width: 600px;
        }
    }


    .tjp {
        padding-top: 50px;
        color: black;
        font-weight: bold;
        text-align: center;
        font-size: 30px;
        letter-spacing: 3px;

        div {
            padding: 10px;
        }
    }

    .text-sx {
        font-size: 13px;
        padding: 4px 0;
    }


    .twopage {
        display: flex;
        align-items: center;
        color: black;
        padding: 0px 10px 10px;
        line-height: 40px;
        font-size: 19px;

        .title {
            font-size: 23px;
            font-weight: 600;
            color: #339966;
        }

        .tw-title-text {
            padding: 10px 0px;
            text-indent: 40px;
        }

        .qr {
            padding-top: 20px;

            .title-text {
                font-size: 20px;
                font-weight: 600;
            }

            text-align: center;

            img {
                width: 200px;
            }
        }
    }


    .three-page, .four-page {
        .title-text {
            font-size: 25px;
            font-weight: bold;
        }
    }

    table {
        border-collapse: collapse;
    }

    table tbody tr {
        line-height: normal !important;
        font-size: 14px;
    }

    table tbody td {
        padding: 5px;
        border: 1px solid black;
        text-align: center;
    }

    table tbody th {
        padding: 5px;
        background: #DDEBF7;
        border: 1px solid black;
        text-align: center;
    }

    .page-footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        border-top: 1.5px solid black;
        text-align: center;
        z-index: 2; /* 底部内容的层叠顺序较低于页面内容 */
    }

    @media print {
        @page {
            margin-bottom: 30px; /* 在每一页的四周留出30px的边距 */
        }
    }

</style>

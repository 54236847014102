<template>
    <div style="width:100%;padding-top: 60px">
        <v-chart autoresize :option="option" style="height: 500px" v-loading="loading"></v-chart>
    </div>
</template>

<script>
    export default {
        name: 'expertBar',
        props: {
            bardata: {
                default: []
            },
        },
        data() {
            return {
                loading: false,
                daterange: [],
                color:['#315494', '#bd6224', '#8b8b8b', '#cf9c06', '#5187b7', '#598a37', "#4875c4", "#e0762f", "#a9a9a9", "#ffc409", "#e1916a", "#b9c3e3", "#ecbcac"],
                option:{}
            }
        },
        methods: {
            getData(){
                var colors = []
                for (let i = 0; i < 13; i++) {
                    var cl = this.color[i];
                    colors.push({
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [{
                            offset: 0, color: cl // 0% 处的颜色
                        }, {
                            offset: 1, color: cl // 100% 处的颜色
                        }],
                        global: false // 缺省为 false
                    },)
                }
                this.option = {
                    title: {
                        text: '2023年度健康体检常见异常',
                        left: 'center',
                    },
                    tooltip: {
                        trigger: 'axis',
                            axisPointer: {
                            type: 'shadow',

                                textStyle: {
                                color: '#05294D',
                            },
                        },
                        backgroundColor: '#05294D',
                            borderColor: '#135469',
                            borderWidth: 2,
                            padding: [20, 20, 20, 20],
                    },
                    grid: {
                        borderWidth: 0,
                            top: '15%',
                            left: '5%',
                            right:'5%',
                            bottom: '15%',
                            textStyle: {
                            color: '#fff',
                        },
                    },
                    legend: {
                        show:false,
                            bottom: '-3%',
                            right: '40%',
                            textStyle: {
                            fontSize: '12px',
                                fontFamily: 'MicrosoftYaHeiUI',
                                color: '#FFFFFF',
                        },
                        itemWidth: 12,
                            itemHeight: 12,
                            data: [
                            {
                                name: '周转量',
                            },
                            {
                                name: '趋势',
                            },
                        ],
                    },
                    xAxis: [
                        {
                            type: 'category',
                            axisLine: {
                                lineStyle: {
                                    // color: 'rgba(255,255,255,.5)',
                                },
                            },
                            splitLine: {
                                show: false,
                            },
                            axisTick: {
                                show: true,
                            },
                            splitArea: {
                                show: false,
                            },
                            axisLabel: {
                                interval: 0,
                                // color: '#32ABCC',
                                fontSize: 12,
                                rotate: 45, // Adjust the rotation angle as needed
                            },
                            data: [
                                "1月",
                                "2月",
                                "3月",
                                "4月",
                                "5月",
                                "6月",
                                "7月",
                                "8月",
                                "9月",
                                "10月",
                                "11月",
                                "12月",
                            ],
                        },
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            splitLine: {
                                show: true,
                                lineStyle: {
                                    color: 'black',
                                    width: 0.3
                                }
                            },
                            axisLine: {
                                show: true,
                            },
                            axisTick: {
                                show: false,
                            },
                            axisLabel: {
                                interval: 0,
                                // color: '#32ABCC',
                                fontSize: 12,
                                formatter: '{value}'
                            },
                            splitArea: {
                                show: false,
                            },
                        },
                    ],
                        series: [
                        {//柱底圆片
                            name: "",
                            yAxisIndex: 0,
                            type: "pictorialBar",
                            symbolSize: [20, 10],//调整截面形状
                            symbolOffset: [0, 5],
                            z: 12,
                            tooltip: {
                                show: true
                            },
                            itemStyle: {
                                "normal": {
                                    color: function(params) {
                                        return colors[params.dataIndex % 7];
                                    }
                                }
                            },
                            label: {
                                show: true,
                                position: 'top',
                                fontSize: 14,
                                fontStyle: 'bold',
                                align: 'center',
                            },
                            data: [102, 78, 53, 49, 39, 38, 35, 29, 28, 25, 20, 19],
                        },
                        //柱体
                        {
                            name: '',
                            type: 'bar',
                            barWidth: 20,
                            yAxisIndex: 0,
                            barGap: '0%',
                            tooltip: {
                                show: false
                            },
                            itemStyle: {
                                "normal": {
                                    color: function(params) {
                                        return colors[params.dataIndex % 7];
                                    }
                                }
                            },
                            data: [102, 78, 53, 49, 39, 38, 35, 29, 28, 25, 20, 19],
                        },
                        //柱顶圆片
                        {
                            name: "",
                            type: "pictorialBar",
                            yAxisIndex: 0,
                            symbolSize: [20, 10],//调整截面形状
                            symbolOffset: [0, -5],
                            z: 12,
                            tooltip: {
                                show: false
                            },
                            symbolPosition: "end",
                            "itemStyle": {
                                "normal": {
                                    color: function(params) {
                                        return colors[params.dataIndex % 7];
                                    }
                                }
                            },
                            data: [102, 78, 53, 49, 39, 38, 35, 29, 28, 25, 20, 19],
                        },
                    ],
                }
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

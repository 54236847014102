<template>
    <div style="width:100%;padding-top: 60px">
        <v-chart autoresize :option="option" style="height: 500px" v-loading="loading"></v-chart>
    </div>
</template>

<script>
    export default {
        name: 'expertBar',
        props: {
            bardata: {
                default: []
            },
        },
        data() {
            return {
                loading: false,
                daterange: [],
                option: {
                    title: {
                        text: '2023年健康体检参检人员年龄分布',
                        left: 'center',
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: { // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                        }
                    },
                    grid: {
                        left: '2%',
                        right: '18%',
                        bottom: '10%',
                        // top: '20%',
                        containLabel: true
                    },
                    legend: {
                        type: 'scroll',
                        orient: 'vertical',
                        top: '20%',
                        right:'8%',
                        itemWidth:15,
                        itemHeight:15,
                        textStyle:{
                            fontSize:10
                        },
                    },
                    xAxis: {
                        type: 'category',
                        data: ['男', '女'],
                        axisLine: {
                            lineStyle: {
                                color: '#8c9493'
                            }
                        },
                        axisLabel: {
                            textStyle: {
                                color:"black",
                                fontSize: 16
                            }
                        },
                    },
                    yAxis: {
                        name: '',
                        nameTextStyle: {
                            color: "#fff"
                        },
                        type: 'value',
                        max: '60',
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#8c9493'
                            }
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: '#8c9493'
                            }
                        },
                        axisLabel: {
                            textStyle: {
                                fontWeight: 600
                            }
                        }
                    },
                    series: [
                        {
                            name: '21~30',
                            type: 'bar',
                            itemStyle: {
                                normal: {
                                    color: '#cb3f3c'
                                },
                            },
                            data: [20, 10]
                        }, {
                            name: '31~40',
                            type: 'bar',
                            itemStyle: {
                                normal: {
                                    color: '#9ac14a'
                                }

                            },
                            data: [30, 20]
                        },
                        {
                            name: '41~50',
                            type: 'bar',
                            itemStyle: {
                                normal: {
                                    color: '#76559d'
                                }

                            },
                            data: [40, 30]
                        },
                        {
                            name: '51~60',
                            type: 'bar',
                            itemStyle: {
                                normal: {
                                    color: '#3c78c0'
                                }

                            },
                            data: [50, 40]
                        }
                    ]
                }
            }
        },
        methods: {},
        mounted() {
            // this.getData()
        }
    }
</script>
